import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyB1lle0nte2ueyKU6fyjgyhQSZOKVfdn2A",
  authDomain: "webits-cool-tools-free-tier.firebaseapp.com",
  projectId: "webits-cool-tools-free-tier",
  storageBucket: "webits-cool-tools-free-tier.appspot.com",
  messagingSenderId: "635441157056",
  appId: "1:635441157056:web:710e8bb8c0ca6ae19f2e25"
};
// Get a Firestore instance
const firebaseSetup = firebase.initializeApp(firebaseConfig)


const db = firebaseSetup.firestore();

export { 
  db
}

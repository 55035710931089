import { createApp } from 'vue'
import AppVue from './App.vue'
import router from './router'
import '@/assets/tailwind.css'

//import af moment som er en date formatter
import moment from 'moment'

//Pusher js
import Pusherjs from './pusher'
const apiKey = '7b95604bd12e1fb96a6b' // FIXME: Use your own API apiKey


import '@/firebase'


//Create app
const App = createApp(AppVue)
//U
  App
  .use(router)
  .use(Pusherjs, { 
    apiKey, 
    cluster: 'eu' 
  })
  .mount('#app')


App.config.globalProperties.$filters = {
  reverseArray(value) {
    return value.slice().reverse();
  },
  removeEndpointFromPath(str, endpoint) {
    return str.replace(endpoint + '/', '')
  },
  jsonQueryToString(query) {
    let str = ''

    let first = false;
    Object.entries(query).forEach(([key, value]) => {
      if(first === false){
        str += "?" + key + "=" + value
        first=true
      }else {
        str += "&" + key + "=" + value
      }
    });

    return str
  },
  unixToDate(unix) {
    return moment(new Date(unix*1000)).format("HH:mm:ss, D-MMMM/YY");
    
  }
}
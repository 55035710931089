import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import firebase from 'firebase';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/endpoint/:endpoint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Endpoint.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/shared/:endpoint/',
    name: 'shared-endpoint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SharedEndpoint.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const user = firebase.auth().currentUser;

  if (to.matched.some(record => record.meta.authRequired)) {

      if (user) {
          next();
      } else {
          console.log('You must be logged in to see this page');
          next({
              path: '/login',
          });
      }
  } else {
      next();
  }
});


export default router

<template>
<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
<div class="relative sm:py-16">

  <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
    <div class="relative rounded-2xl px-6 py-10 bg-indigo-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
      <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
        <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
          <path class="text-indigo-500 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
          <path class="text-indigo-700 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
        </svg>
      </div>
      <div class="relative">
        <div class="text-center">
            
          <h2 class="text-gray-200 text-base tracking-wide uppercase">
            Debug web hooks, http clients, etc.
          </h2>
          <h1 class="mt-1 text-5xl font-extrabold text-gray-50">
            Webits Http Request Catcher
          </h1>
          <p class="mt-6 mx-auto max-w-2xl text-base text-indigo-100">
            Webits Http Request Catcher will create a endpoint on which you can test an application. All requests sent to any path on the endpoint, are forwarded to your browser in real time.
          </p>
        </div>
        
        <form @submit.prevent="goToEndpoint()" class="mt-12 sm:mx-auto sm:max-w-xl sm:flex">
          
          <div class=" flex rounded-md shadow-sm">
            <span class="inline-flex items-center px-3 rounded-l-md bg-gray-100 text-gray-700">
              https://req-catch.webits.dk/
            </span>
            <input placeholder="my-endpoint" type="text" v-model="endpoint" name="http-endpoint" id="http-endpoint" class="text-lg flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:border-transparent focus:outline-none border-gray-300" >
          </div>
          <div class="ml-4">
            <button type="submit" class="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-500 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10">Go!</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


</div>

</template>

<script>
export default {
  data() {
    return {
      endpoint: ''
    }
  },

  methods: {
    goToEndpoint(){
      if(!this.endpoint == ''){
      this.$router.push('/endpoint/' + this.endpoint)
      }
    }
  },

}
</script>

<style>

</style>